<template>
  <teleport to="#modalWrap">
    <div
      class="modal d-block"
      tabindex="-1"
    >
      <div
        :class="$style.backdrop"
        @click="setIsModalActive(false)"
      >
        <div
          :class="$style.backdropImage"
          :style="{
            backgroundImage: `url('${require(`@/assets/img/home-category/${category}/img (${selectedImgIndex}).jpg`)}')`
          }"
        />
      </div>
      <div
        class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
      >
        <div
          class="modal-content h-100 border-0"
          :class="$style.modal"
        >
          <div class="modal-header border-0">
            <Icon
              data-bs-dismiss="modal"
              aria-label="Close"
              class="ms-auto"
              :class="$style.close"
              name="close"
              role="button"
              @click="setIsModalActive(false)"
            />
          </div>
          <div class="modal-body p-4">
            <div class="container-fluid">
              <div class="row position-relative">
                <div class="col-12 col-md-9 mb-3 order-1 order-md-2">
                  <Slider
                    v-if="category"
                    ref="mainSlider"
                    :allow-touch-move="true"
                    :slides-per-view="1"
                    :show-navigation="true"
                    class="h-100"
                    @slideChange="slideChange"
                  >
                    <template #default>
                      <SwiperSlide
                        v-for="number in categoryConfig.imgNumber"
                        :key="number"
                      >
                        <div :class="$style[`imageZoomWrapper`]">
                          <InnerImageZoom
                            class="position-absolute"
                            :src="
                              require(`@/assets/img/home-category/${category}/img (${selectedImgIndex}).jpg`)
                            "
                            :zoom-src="
                              require(`@/assets/img/home-category/${category}/img (${selectedImgIndex}).jpg`)
                            "
                          />
                        </div>
                      </SwiperSlide>
                    </template>
                  </Slider>
                </div>
                <!-- thumbnail -->
                <div class="col-12 col-md-3 mb-3 order-2 order-md-1">
                  <Slider
                    ref="subSlider"
                    :allow-touch-move="true"
                    :breakpoints="{
                      0: {
                        slidesPerView: 4
                      },
                      768: {
                        slidesPerView: 4
                      },
                      1024: {
                        slidesPerView: 4
                      }
                    }"
                    :space-between="14"
                    class="h-100"
                    :direction="subSliderDirection"
                  >
                    <template #default>
                      <SwiperSlide
                        v-for="number in categoryConfig.imgNumber"
                        :key="number"
                        class="col-3 col-md-12 p-0"
                        @mouseover="setSlideIndex(number)"
                      >
                        <div :class="$style.imgWrap">
                          <img
                            class="w-100 h-100 position-absolute top-0"
                            :class="[
                              $style[`objectFitCover`],
                              $style[`transitionBorder`],
                              [
                                number === selectedImgIndex
                                  ? [$style[`borderPrimary`]]
                                  : [$style[`borderTransparent`]]
                              ]
                            ]"
                            :src="
                              require(`@/assets/img/home-category/${category}/img (${number}).jpg`)
                            "
                          >
                        </div>
                      </SwiperSlide>
                    </template>
                  </Slider>
                </div>
                <div class="col-12 order-2 order-md-3">
                  <div
                    class="d-flex flex-column align-items-center text-center"
                  >
                    <h4
                      class="border borderPrimary border-top-0 border-bottom-0 text-primary d-inline-block px-3 mb-3"
                    >
                      {{ categoryConfig.title }}
                    </h4>
                    <Icon
                      class="mb-3"
                      :class="$style.diamond"
                      :name="categoryConfig.iconName"
                    />
                    <p :class="$style.whiteSpacePre">
                      {{ categoryConfig.introduction }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { computed, ref, toRef } from 'vue-demi'
import Slider from '@/components/Slider.vue'
import { SwiperSlide } from 'swiper/vue'
import { useStore } from 'vuex'
import InnerImageZoom from 'vue-inner-image-zoom'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'

export default {
  name: 'HomeModalPortfolio',
  components: {
    Slider,
    SwiperSlide,
    InnerImageZoom
  },
  props: {
    category: {
      type: String,
      default: ''
    }
  },
  emits: ['setIsModalActive'],
  setup (props, { emit }) {
    const { getters } = useStore()

    const isMobile = computed(() => getters.getIsMobile)
    const subSliderDirection = computed(() =>
      isMobile.value ? 'horizontal' : 'vertical'
    )

    // onMounted(() => {
    //   const innerWidth = window.windowInnerWidth
    //   commit('setIsMobile', innerWidth < 768)

    //   window.addEventListener('resize', () => {
    //     const innerWidth = window.windowInnerWidth
    //     commit('setIsMobile', innerWidth < 768)
    //   })
    // })

    const mainSlider = ref(null)
    const subSlider = ref(null)
    const selectedImgIndex = ref(1)
    const setSlideIndex = (index) => {
      selectedImgIndex.value = index
      mainSlider.value.setSlideIndex(index - 1)
    }
    const slideChange = (sliderIndex) => {
      selectedImgIndex.value = sliderIndex + 1
      subSlider.value.setSlideIndex(sliderIndex)
    }

    const setIsModalActive = (active) => {
      emit('setIsModalActive', active)
    }

    const category = toRef(props, 'category')
    const categoryMap = {
      'home-category-11': {
        iconName: 'diamond',
        imgNumber: 3,
        title: '2021年度人緣桃花系列',
        introduction:
          '老師依照年度大運精算出的設計款\n可以加強自身桃花及貴人運和好人緣\n是一款適合所有的朋友來配戴的好設計\n如果你平常很需要長官賞識\n或是平常很需要蒐秀交際的朋友\n或是目前還在猶豫未來的人生方向\n都很需要這個系列的設計款\n讓長輩、同事、長官或好朋友幫你一把'
      },
      'home-category-12': {
        iconName: 'diamond',
        imgNumber: 4,
        title: '除穢擋煞系列',
        introduction:
          '年度除穢款 \n把這一整年的壞事，通通都先清走\n可以抗煞氣，除去自己身上的負面能量，才能迎接更美好的未來\n老師依照每年的大運精算指導的年度設計 \n維持身體和心靈的正面與平和 \n排除身上各種負面能量 \n阻擋煞氣與病氣\n是每個人都必備的一條年度設計'
      },
      'home-category-13': {
        iconName: 'diamond',
        imgNumber: 8,
        title: '五行本命系列',
        introduction:
          '依照老師精算出的個人化生命之鑰五行需求 \n當年的年度大運走向 、四季節氣變化、多方配合下\n共同製作出來的五行(木火土金水)藝術設計 \n你可以進一步的依照自己五行所缺去輔助配戴'
      },
      'home-category-14': {
        iconName: 'diamond',
        imgNumber: 1,
        title: '招財利系列',
        introduction:
          '專業的老師針對不同的工作性質\n提出不同的專業指導 \n讓大家都可以好好把握來年的財利的流年大運\n創造出更多工作與財富的奇蹟就靠它了'
      },
      'home-category-15': {
        iconName: 'diamond',
        imgNumber: 2,
        title: '健康系列',
        introduction:
          '全球疫情爆發後 \n希望大家要更加注意自身健康 \n有健康的體魄當基礎\n才有後續的夢想起飛'
      },
      'home-category-16': {
        iconName: 'diamond',
        imgNumber: 6,
        title: '星際隕石之旅',
        introduction:
          '天外隕石的能量以及震動頻率都非常的高\n可以當作護身符使用 \n也能夠透過高震動頻率向宇宙下訂單（許願）\n一條手鍊就可以一次集好集滿多種天然隕石 \n想要收藏與體驗滿滿的天然隕石能量 \n就不能錯過它'
      },
      'home-category-21': {
        iconName: 'diamond-line-2',
        imgNumber: 38,
        title: '精品寶石能量設計',
        introduction:
          '每年度針對節氣、節慶來搭配寶石的能量\n利用寶石和大自然的能量\n調整人體機能和穩定情緒\n維持身心靈平衡的狀態\n\n讓我們隨著時節配戴\n更能相輔相成的寶石藝術設計系列'
      },
      'home-category-22': {
        iconName: 'diamond-line-2',
        imgNumber: 9,
        title: '生命之鑰',
        introduction:
          ' 人生活在世間上\n受先天五行所影響命與運\n是不可能避免的\n\n借助於寶石的能量五行與向善的心\n讓我們的生命之鑰\n帶給姐妹們更正面、更自信的態度\n通關此生必經歷的考驗\n並且進一步圓滿自己\n努力完善這世的各種功課…\n\n每一年都期許自己\n朝著不同的願望與目標來努力\n使得我們一年比一年過得更充實，更滿足，更快樂\n\n希望每個人 每一年獨特的生命之鑰寶石手鍊\n能給予我們更多溫暖的力量\n陪伴我們前行'
      },
      'home-category-23': {
        iconName: 'diamond-line-2',
        imgNumber: 9,
        title: '阿卡&金剛砂',
        introduction:
          '這兩款稀有老礦\n都能增加氣血循環\n是可以活化身體機能的好物\n\n・天然的老礦西藏阿卡\n能量平穩強大，能夠平衡磁場使心情平和\n護身辟邪，心想事成、促進血液循環…\n氣血順了，身體機能和精神都會更好\n還有朋友說可以向阿卡許願，聽說也是很靈驗的！\n\n・金剛砂\n可以舒緩身體的酸痛\n幫助血液循環，傷口復原，安眠，穩定身心等等\n還有姊妹說戴上後就感覺到一股熱熱的暖流\n\n稀缺的天然老礦藝術設計\n帶給我們美好的能量'
      },
      'home-category-24': {
        iconName: 'diamond-line-2',
        imgNumber: 14,
        title: '日本珠寶',
        introduction:
          '我們是以國際的日本珠寶展和香港珠寶展為主\n由娜娜本人親自到國外展會\n帶回最優質與獨特的珠寶設計商品\n內容包含了\n日本製k金、鉑金鑽石商品、\n彩色寶石與天然裸肌鑽、日本Akoya珍珠\n以及富有設計感的18K耳環、手鍊等多項商品\n\n採用即時直播連線的方式\n為您挑選妳專屬的珠寶商品'
      },
      'home-category-25': {
        iconName: 'diamond-line-2',
        imgNumber: 8,
        title: '天然木設計&木質精油',
        introduction:
          '嚴選完全自然且稀有的天然香木、\n檀香、降真香、綠檀、沉香等等\n項目包含\n天然的香木珠設計和各式天然線香，\n盤香香品及精油和純露等許多不同的品項\n\n藉由樸實自然的天然木\n從配戴或者是利用焚香或熏香等等方式\n釋放天然芬多精放鬆我們身心靈\n同時也能淨化所在的空間磁場\n進一步保護與淨化自身的能量'
      }
    }
    const categoryConfig = computed(() => categoryMap[category.value])

    return {
      subSliderDirection,
      mainSlider,
      subSlider,
      selectedImgIndex,
      setIsModalActive,
      setSlideIndex,
      categoryConfig,
      slideChange
    }
  }
}
</script>

<style lang="scss" module>
.imageZoomWrapper {
  padding-bottom: 150%;
}

.objectFitCover {
  object-fit: cover;
}

.transitionBorder {
  transition: border 0.3s;
}

.borderTransparent {
  border: solid 1px transparent;
}

.borderPrimary {
  border: solid 2px $secondary;
}

.whiteSpacePre {
  white-space: pre;
}

.close {
  fill: #b5b5b5;
  height: 1.25rem;
  width: 1.25rem;
}

.diamond {
  fill: $primary;
  height: 2.2rem;
  width: 2.2rem;
}

.backdrop {
  position: fixed;
  z-index: $zindex-modal-backdrop;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $white;
}

.modal {
  z-index: $zindex-modal;
  box-shadow: 0px 0px 12px rgba($black, 0.15);
}

.backdropImage {
  position: fixed;
  z-index: $zindex-modal-backdrop;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($black, 0.3);
  background-size: cover;
  filter: blur(45px);
  background-position: top right;
  transition: background-image 0.3s;
  will-change: background-image;
}

.imgWrap {
  padding: 75% 0;
  height: 0;
  overflow: hidden;
}
</style>
