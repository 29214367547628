<template>
  <main>
    <div class="container-fluid gx-0 mb-5">
      <div class="row gx-0 position-relative">
        <img
          class="d-none d-xxl-block col-12"
          src="@/assets/img/home-banner-0-xxl.png"
        >
        <img
          class="d-none d-md-block d-xxl-none col-12"
          src="@/assets/img/home-banner-0-md.png"
        >
        <img
          class="d-block d-md-none col-12"
          src="@/assets/img/home-banner-0-sm.png"
        >
        <div
          class="col-12 col-md-6 position-absolute end-0 top-50 translate-middle-y text-white text-center"
        >
          <h2
            class="fw-bold mb-4"
            :class="$style.letterSpacing10"
          >
            娜娜家
          </h2>
          <p
            class="mb-4"
            :class="$style.mainBannerDesc"
          >
            希望每一位愛好寶石的朋友來到這裡<br>
            都能感受到溫馨與舒適<br>
            在良好的氛圍下挑選自己需要的能量寶藏<br>
            同時更希望每個人都能了解<br>
            自己是多麼不凡的個體<br>
            就如同天然寶石一般<br>
            等待開採臨世、雕琢、拋光、成為大放異彩的個體<br>
            願娜娜家寶石的能量開發出妳的無限可能<br>
          </p>
          <a
            href="https://www.facebook.com/groups/Natasha1001tw/"
            target="_blank"
            class="btn btn-primary rounded-pill"
          >立即前往FB社團</a>
        </div>
      </div>
    </div>

    <!-- 年度寶石作品集 你值得擁有 -->
    <section
      id="aboutus"
      class="container-lg"
    >
      <section class="row mb-4">
        <img
          class="d-none d-xxl-block col-12"
          src="@/assets/img/home-banner-1-xxl.png"
        >
        <img
          class="d-none d-md-block d-xxl-none col-12"
          src="@/assets/img/home-banner-1-md.png"
        >
        <img
          class="d-block d-md-none col-12"
          src="@/assets/img/home-banner-1-sm.png"
        >
      </section>

      <!-- category -->
      <div class="row mb-5">
        <!-- category lg -->
        <div
          v-for="(category, index) in category1List"
          :key="index"
          class="d-none d-md-flex col-12 col-md-4 mb-4"
          role="button"
          @click="setModalShowCategory(`home-category-1${index + 1}`)"
        >
          <div :class="[$style[`category`], $style[`category1`]]">
            <div class="w-100 position-relative rounded-2 overflow-hidden">
              <img
                class="img-fluid"
                :class="$style[`categoryImg`]"
                :src="require(`@/assets/img/home-category-1${index + 1}.png`)"
                alt=""
              >
              <div
                :class="[
                  $style[`categoryInfoBlock`],
                  $style[`categoryInfoText`]
                ]"
                class="w-100 d-flex flex-column align-items-center py-2"
              >
                <span class="fs-5">{{ category.title }}</span>
                <span>{{ category.year }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- category sm -->
        <!-- swiper -->
        <div class="col-12 mb-4">
          <Slider
            ref="categorySlider"
            :allow-touch-move="false"
            class="d-md-none"
            :slides-per-view="1"
            :space-between="40"
          >
            <template #default>
              <SwiperSlide
                v-for="(category, index) in category1List"
                :key="index"
                class="mb-3"
                role="button"
                @click="setModalShowCategory(`home-category-1${index + 1}`)"
              >
                <div :class="$style[`category`]">
                  <div
                    class="w-100 position-relative rounded-2 overflow-hidden"
                  >
                    <img
                      class="img-fluid"
                      :class="$style[`categoryImg`]"
                      :src="
                        require(`@/assets/img/home-category-1${index + 1}.png`)
                      "
                      alt=""
                    >
                    <div
                      :class="[
                        $style[`categoryInfoBlock`],
                        $style[`categoryInfoText`]
                      ]"
                      class="w-100 d-flex flex-column align-items-center py-2"
                    >
                      <span class="fs-5">{{ category.title }}</span>
                      <span>{{ category.year }}</span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </template>
          </Slider>
        </div>
        <!-- button swiper arrow -->
        <div class="col-12 mb-4 d-flex d-md-none justify-content-center">
          <Icon
            name="arrow-left-circle"
            role="button"
            :class="[
              $style.icon,
              { [$style.opacity8]: categorySliderIndex === 0 }
            ]"
            @click="setCategorySliderIndex(-1)"
          />
          <Icon
            name="arrow-right-circle"
            role="button"
            :class="[
              $style.icon,
              {
                [$style.opacity8]:
                  categorySliderIndex === category1List.length - 1
              }
            ]"
            @click="setCategorySliderIndex(1)"
          />
        </div>

        <!-- button /product/list -->
        <div class="col-12 mb-3 text-center">
          <!-- <router-link
            to="/product/list"
            class="btn btn-primary rounded-pill"
          >
            點我探索更多作品
          </router-link> -->
          <button
            type="button"
            class="btn btn-primary rounded-pill"
            @click="HometoProduct"
          >
            點我探索更多作品
          </button>
        </div>
      </div>
    </section>

    <!-- 關於娜娜家 做最真實的自己 -->
    <section
      id="company"
      class="container-lg mb-4"
    >
      <section class="row mb-4">
        <img
          class="d-none d-xxl-block col-12"
          src="@/assets/img/home-banner-2-xxl.png"
        >
        <img
          class="d-none d-md-block d-xxl-none col-12"
          src="@/assets/img/home-banner-2-md.png"
        >
        <img
          class="d-block d-md-none col-12"
          src="@/assets/img/home-banner-2-sm.png"
        >
      </section>

      <section class="row mb-4 position-relative">
        <!-- 娜娜家 -->
        <div
          class="col-12 col-xxl-5 d-flex flex-column align-items-center mb-4"
        >
          <div class="mb-4">
            <img
              class="d-none d-xxl-block w-100"
              src="@/assets/img/home-section-1-xxl.png"
            >
            <img
              class="d-none d-md-block d-xxl-none w-100"
              src="@/assets/img/home-section-1-md.png"
            >
            <img
              class="d-block d-md-none w-100"
              src="@/assets/img/home-section-1-sm.png"
            >
          </div>
          <a
            href="https://www.facebook.com/groups/Natasha1001tw/"
            target="_blank"
            class="btn btn-primary rounded-pill"
          >
            立即前往FB社團
          </a>
        </div>
        <!-- background -->
        <div
          class="col-12 col-xxl-7 d-flex align-items-center justify-content-center"
        >
          <img
            class="w-75"
            :class="$style.sectionBg"
            src="@/assets/img/home-section-1-bg.png"
          >
        </div>
      </section>

      <section class="row mb-4 position-relative">
        <!-- background -->
        <div
          class="col-12 col-xxl-7 d-flex align-items-center justify-content-center"
        >
          <img
            class="w-75"
            :class="$style.sectionBg"
            src="@/assets/img/home-section-2-bg.png"
          >
        </div>
        <!-- 公司沿革 -->
        <div class="col-12 col-xxl-5">
          <img
            class="d-none d-xxl-block w-100"
            src="@/assets/img/home-section-2-xxl.png"
          >
          <img
            class="d-none d-md-block d-xxl-none w-100"
            src="@/assets/img/home-section-2-md.png"
          >
          <img
            class="d-block d-md-none w-100"
            src="@/assets/img/home-section-2-sm.png"
          >
        </div>
      </section>
    </section>

    <!-- 精品寶石設計作品集 每個人都是獨特的存在 -->
    <section class="container-lg mb-4">
      <section class="row">
        <img
          class="d-none d-xxl-block col-12"
          src="@/assets/img/home-banner-3-xxl.png"
        >
        <img
          class="d-none d-md-block d-xxl-none col-12"
          src="@/assets/img/home-banner-3-md.png"
        >
        <img
          class="d-block d-md-none col-12"
          src="@/assets/img/home-banner-3-sm.png"
        >
      </section>
    </section>
    <section class="container-lg mb-4">
      <section class="row">
        <!-- category lg -->
        <div
          v-for="(block, blockIndex) in category2List"
          :key="blockIndex"
          :class="block.class"
        >
          <div class="row">
            <div
              v-for="category in block.children"
              :key="category.index"
              class="pb-2 mb-3"
              :class="category.class"
              role="button"
              @click="setModalShowCategory(`home-category-2${category.index}`)"
            >
              <div :class="[$style[`category`], $style[`category2`]]">
                <div class="w-100 position-relative rounded-2 overflow-hidden">
                  <!-- md -->
                  <img
                    class="d-none d-md-block w-100 img-fluid"
                    :class="$style[`categoryImg`]"
                    :src="
                      require(`@/assets/img/home-category-2${category.index}-md.png`)
                    "
                    alt=""
                  >
                  <!-- md-text -->
                  <img
                    class="d-none d-md-block w-100 img-fluid"
                    :class="$style[`categoryInfoBlock`]"
                    :src="
                      require(`@/assets/img/home-category-2${category.index}-md-text.png`)
                    "
                    alt=""
                  >
                  <!-- sm -->
                  <img
                    class="d-block d-md-none w-100 img-fluid"
                    :class="$style[`categoryImg`]"
                    :src="
                      require(`@/assets/img/home-category-2${category.index}-sm.png`)
                    "
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>

    <HomeModalPortfolio
      v-if="isModalActive"
      :category="modalShowCategory"
      @set-is-modal-active="setIsModalActive"
    >
      <template #header>
        header
      </template>
      <template #body>
        body
      </template>
    </HomeModalPortfolio>
  </main>
</template>
<script>
import { inject, ref, computed, onMounted } from 'vue'
import Slider from '@/components/Slider.vue'
import HomeModalPortfolio from '@/components/HomeModalPortfolio.vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import { SwiperSlide } from 'swiper/vue'
export default {
  name: 'Home',
  components: {
    Slider,
    HomeModalPortfolio,
    SwiperSlide
  },
  async setup () {
    const { push, replace } = useRouter()
    const { name } = useRoute()
    const { getters } = useStore()

    const memberID = computed(() => getters['member/getMemberID'])

    const setAlert = inject('setAlert')

    const isAboutusActive = ref(false)
    const isCompanyActive = ref(false)

    const categorySlider = ref(null)
    const categorySliderIndex = ref(0)
    const setCategorySliderIndex = (index) => {
      const newIndex = categorySliderIndex.value + index
      if (newIndex >= 0 && newIndex <= category1List.length - 1) {
        categorySliderIndex.value = newIndex
        categorySlider.value.setSlideIndex(newIndex)
      }
    }

    const modalShowCategory = ref(null)
    const setModalShowCategory = (category) => {
      modalShowCategory.value = category
      setIsModalActive(true)
    }

    const isModalActive = ref(false)
    const setIsModalActive = (active) => {
      isModalActive.value = active
    }

    const HometoProduct = async () => {
      if (!memberID.value) {
        setAlert(true, false, '請登入會員！')
        push('/login')
      } else {
        push('/product/list')
      }
    }

    const replaceRoute = () => {
      if (name === 'Home') {
        if (isAboutusActive.value && !isCompanyActive.value) {
          replace('/#aboutus')
        } else if (isCompanyActive.value) {
          replace('/#company')
        } else {
          replace('/')
        }
      }
    }

    const observer = ref(null)
    onMounted(() => {
      const callback = (entries, observer) => {
        document.removeEventListener('scroll', scrollEventListener)
        entries.forEach(entry => {
          if (entry.target.id === 'aboutus') {
            isAboutusActive.value = entry.isIntersecting
          } else if (entry.target.id === 'company') {
            isCompanyActive.value = entry.isIntersecting
          }
          replaceRoute()
        })
      }
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1]
      }

      const scrollEventListener = document.addEventListener('scroll', (event) => {
        observer.value = new IntersectionObserver(callback, options)
        observer.value.observe(document.querySelector('#aboutus'))
        observer.value.observe(document.querySelector('#company'))
        document.removeEventListener('scroll', scrollEventListener)
      }, { once: true })
    })

    onBeforeRouteLeave((to, from) => {
      try {
        observer.value.unobserve(document.querySelector('#aboutus'))
        observer.value.unobserve(document.querySelector('#company'))
      } catch (error) {

      }
    })
    const category1List = [
      {
        title: '人緣桃花系列',
        year: '2021'
      },
      {
        title: '除穢抗煞系列',
        year: '2018-2021'
      },
      {
        title: '五行本命系列',
        year: '2021'
      },
      {
        title: '招財利系列',
        year: '2021'
      },
      {
        title: '健康系列',
        year: '2020-2021'
      },
      {
        title: '星際隕石之旅',
        year: '2019-2021'
      }
    ]

    const category2List = [
      {
        class: 'col-12 col-lg-6',
        children: [{ index: 1, class: 'col-12' }]
      },
      {
        class: 'col-12 col-lg-6',
        children: [
          { index: 2, class: 'col-12 col-md-6' },
          { index: 3, class: 'col-12 col-md-6' },
          { index: 4, class: 'col-12 col-md-6' },
          { index: 5, class: 'col-12 col-md-6' }
        ]
      }
    ]

    return {
      modalShowCategory,
      setModalShowCategory,
      isModalActive,
      setIsModalActive,
      categorySlider,
      setCategorySliderIndex,
      categorySliderIndex,
      category1List,
      category2List,
      HometoProduct
    }
  }
}
</script>
<style lang="scss" module>
.letterSpacing10 {
  letter-spacing: 10px;
}

.mainBannerDesc {
  line-height: 2.5;
}

@include media-breakpoint-down(md) {
  .mainBannerDesc {
    font-size: 0.875rem;
  }
}
.icon {
  margin: 0 0.75rem;
  fill: $secondary;
  height: 2.5rem;
  width: 2.5rem;
}

.sectionBg {
  z-index: -1;
}

@include media-breakpoint-down(xxl) {
  .sectionBg {
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}

.opacity8 {
  opacity: 0.8;
}

/* category */
.category .categoryImg {
  transition: transform 0.3s;
}
.category1 .categoryImg {
  transform-origin: top right;
}
.category2 .categoryImg {
  transform-origin: center;
}

.category .categoryInfoBlock {
  border-radius: 0;
  color: $white;
  position: absolute;
  left: 0;
  bottom: 0;
}

.category .categoryInfoText {
  background-color: rgba($secondary, 0.9);
}

.category:hover .categoryImg {
  transform: scale(1.1);
}
</style>
